<template>
    <div class="partner-highlight-container">
        <div class="partner-highlight">
            <div class="partner-highlight__content">
                <WatsonAtomImage
                    v-if="content.logo"
                    :content="content.logo"
                    class="partner-highlight__logo"
                />

                <div
                    v-if="(content.title && showTitle) || (content.date && content.type === 'media' && showDate)"
                    class="partner-highlight__title-container"
                >
                    <p
                        v-if="content.title && showTitle"
                        class="partner-highlight__title u-heading-4"
                    >
                        {{ content.title }}
                    </p>

                    <p
                        v-if="content.date && content.type === 'media' && showDate"
                        class="partner-highlight__date u-tiny-text"
                    >
                        {{ content.date }}
                    </p>
                </div>

                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="partner-highlight__text u-tiny-text"
                    v-html="content.text"
                ></div>
            </div>

            <a
                v-if="showLink && content.url"
                :href="content.url"
                class="partner-highlight__url u-bold u-tiny-text"
                target="_blank"
            >
                {{ translations.url }}
            </a>
        </div>
    </div>
</template>

<script setup>
import WatsonAtomImage from '../../../../components/atoms/image/watson-atom-image.vue';
import { usePage } from '@inertiajs/vue3';

defineProps({
    content: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    showDate: {
        type: Boolean,
        required: false,
        default: true,
    },
    showLink: {
        type: Boolean,
        required: false,
        default: true,
    },
    showTitle: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const page = usePage();
const translations = page.props.settings.translations.partnerHighlight;
</script>

<style lang="scss">
@use 'watson-theme-partner-highlight';
</style>
